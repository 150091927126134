import React from 'react';
import { StaticQuery, graphql } from 'gatsby';

import { hasAnEdge } from '../../functions';
import { dataEdge } from '../../functions';
import * as styles from './modules/rea.module.scss';

/**
 * Accompagnement BeMum
 */
function Component({ data }) {
  const { allStaticReaYaml } = data;
  const items = hasAnEdge(allStaticReaYaml) ? allStaticReaYaml.edges : [];
    return (
    <section className={styles.container}>
      {items.map((item, index) => (
        <div className={styles.items}>
          {index === 1 ? (
            <div className={styles.imageContainer1}>
              <img src={item.node.image.publicURL} alt={item.node.alt} />
            </div>
          ) : (
            <div className={styles.imageContainer}>
              <img src={item.node.image.publicURL} alt={item.node.alt} />
            </div>
          )}

          <p className={styles.text}>{item.node.description}</p>
        </div>
      ))}
    </section>
  );
}

export default function (props) {
  return (
    <StaticQuery
      render={(data) => <Component data={data} {...props} />}
      query={graphql`
        {
          allStaticReaYaml {
            edges {
              node {
                image {
                  publicURL
                }
                alt
                description
              }
            }
          }
        }
      `}
    />
  );
}
