import React, { useContext } from "react";
import CartContext from "../../context/CartContext";
import { Link } from "gatsby";
import * as style from "./cart-icon.module.scss";
import { ShoppingCart } from 'lucide-react';

const CartIcon = () => {
  const { cart } = useContext(CartContext);

  const totalItems = cart.contents.program && cart.contents.program  === "shop" ? cart.contents.items.reduce(
    (total, item) => total + item.quantity,
    0
  ) : cart.contents.program && 1;

  return (
    <div className={style.cartIcon}>
      <Link to="/panier">
      <ShoppingCart className={style.cartIconImage} />
        {totalItems > 0 && (
          <span className={style.cartCount}>{totalItems}</span>
        )} 
      </Link>
    </div>
  );
};

export default CartIcon;
