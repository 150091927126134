import React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import { styled } from '@stitches/react';

export default function FertilityTestCta({ label, display = 'none', justifyContent = 'start' }) {
  const data = useStaticQuery(graphql`
    query {
      allStaticNavbarYaml {
        nodes {
          fertilityTest {
            alt
            img {
              publicURL
            }
          }
        }
      }
    }
  `);

  const StyledLabel = styled('span', {
    marginLeft: '10px',
    fontSize: '16px',
    letterSpacing: '0.64px',
    fontWeight: 500,
    whiteSpace: 'nowrap',
  });

  const StyledLink = styled(Link, {
    margin: '10px',
    alignItems: 'center',
    justifyContent,
    height: '50px',
    padding: '10px 20px',
    backgroundColor: '#1A2240',
    color: '#FFFFFF',
    borderRadius: '10px',
    textDecoration: 'none',
    display,
    transition: 'background-color 0.3s ease, transform 0.2s ease',
    '@media only screen and (min-width: 1020px)': {
      display: 'flex',
    },
    '&:hover': {
      backgroundColor: '#121833',
      color: '#E4E4E4',
      boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.2)',
      transform: 'translateY(-2px)',
      cursor: 'pointer',
    },
  });

  return (
    <StyledLink to="/test-fertilite/">
      {/* <img
        src={data.allStaticNavbarYaml.nodes[0].fertilityTest.img.publicURL}
        alt={data.allStaticNavbarYaml.nodes[0].fertilityTest.alt}
      /> */}
      <StyledLabel>{label}</StyledLabel>
    </StyledLink>
  );
}
